.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%);
}

.login .MuiCard-root {
  display: block;
  text-align: center;
  width: 300px;
  margin: 6rem 0 1rem;
  overflow: hidden;
}

.login .MuiCard-root .header {
  margin: 1em;
  display: flex;
  justify-content: center;
}

.login .MuiCard-root .container {
  padding: 0 1em 1em 1em;
}

.MuiButton-root.secondary {
  margin-bottom: auto;
}

.secondary .MuiButton-label {
  color: white;
}
